import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

const firebaseConfig = {
  apiKey: "AIzaSyD60y-PW-AKJaJOZnoOYm7v5vUyq2Zzcj0",
  authDomain: "flexforce-6042c.firebaseapp.com",
  projectId: "flexforce-6042c",
  storageBucket: "flexforce-6042c.appspot.com",
  messagingSenderId: "814425360856",
  appId: "1:814425360856:web:c97779ea4341036fdc8aad",
  measurementId: "G-GS36YCNPL9",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Redirect to="/admin" />
      </Route>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
