import React from "react";
import { Card, CardHeader, Input, Container, Row } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { LineWave } from "react-loader-spinner";
import { Badge } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";

class Sortable extends React.Component {
  renderVerifiedBadge = (params) => {
    const { value } = params;
    return value ? (
        <Badge color="success">Verified</Badge>
    ) : (
        <Badge color="warning">Not Verified</Badge>
    );
  };

  state = {
    workers: [],
    loading: true,
    error: null,
    columnDefs: [],
    defaultColDef: {
      sortable: true,
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    paginationPageSize: 20,
    pagination: true,
    searchTerm: "",
  };

  async componentDidMount() {

    const queryParams = this.getQueryParams();
    window.history.replaceState({}, document.title, window.location.pathname);

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken && Object.keys(queryParams).length > 0 &&
        queryParams.otp &&
        queryParams.email) {
      await this.handleLoginWithOTP(queryParams);
    } else if (accessToken) {
      await this.fetchWorkersData(accessToken);
    } else {
      // Redirect to pricing page
      window.location.href = "https://www.flexforce.ai/";
    }
  }

  getQueryParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {};
    for (const [key, value] of urlParams.entries()) {
      queryParams[key] = value;
    }
    return queryParams;
  };

  handleLoginWithOTP = async (queryParams) => {
    fetch("https://flexforce-ai-50842c1c3cfd.herokuapp.com/auth/login-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp: queryParams.otp,
        email: queryParams.email,
      }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to login with OTP");
      }
      return response.json();
    }).then((data) => {
      localStorage.setItem("accessToken", data.access_token);
      this.fetchWorkersData(data.access_token);
    }).catch((error) => {
      console.error("Error logging in with OTP:", error);
    });
  };

  fetchWorkersData = async (accessToken) => {
    try {
      const response = await fetch(
          "https://flexforce-ai-50842c1c3cfd.herokuapp.com/workers/", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
      if (!response.ok) {
        throw new Error("Failed to fetch workers data");
      }
      const data = await response.json();
      const workersWithDefaults = data.map(worker => ({
        ...worker,
        interests: Array.isArray(worker.interests) && worker.interests.length >
        0 ? worker.interests : "Not Available",
        work_experiences: Array.isArray(worker.work_experiences) &&
        worker.work_experiences.length > 0
            ? worker.work_experiences
            : "Not Available",
        zipcode: worker.zipcode || "Not Available",
        // Add more fields with default values if needed
      }));
      this.setState({
        workers: workersWithDefaults,
        loading: false,
        error: null,
      });
      // Initialize columnDefs after workers data is fetched
      this.initializeColumnDefs();
    } catch (error) {
      console.error("Error fetching workers data:", error);
      this.setState({ loading: false, error: error.message });
    }
  };

  initializeColumnDefs = () => {
    const columnDefs = [
      { headerName: "Name", field: "name" },
      { headerName: "Email", field: "email" },
      { headerName: "Phone", field: "phone" },
      { headerName: "Zipcode", field: "zipcode" },
      {
        headerName: "Interests",
        field: "interests",
      },
      {
        headerName: "Work Experiences",
        field: "work_experiences",
      },
      {
        headerName: "Verified",
        field: "is_verified",
        cellRendererFramework: this.renderVerifiedBadge,
      },
    ];
    this.setState({ columnDefs });
  };

  handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ searchTerm });
  };

  render() {
    const {
      workers,
      loading,
      error,
      columnDefs,
      defaultColDef,
      paginationPageSize,
      pagination,
      searchTerm,
    } = this.state;

    const filteredWorkers = workers.filter(
        (worker) =>
            (worker.zipcode && worker.zipcode.includes(searchTerm)) ||
            (Array.isArray(worker.interests) &&
                worker.interests.some((interest) =>
                    interest.toLowerCase().includes(searchTerm),
                )) ||
            (Array.isArray(worker.work_experiences) &&
                worker.work_experiences.some((experience) =>
                    experience.toLowerCase().includes(searchTerm),
                )) ||
            worker.name.toLowerCase().includes(searchTerm),
    );

    const spinnerContainerStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      zIndex: 9999,
    };

    if (loading) {
      return (
          <LineWave
              className="d-flex justify-content-center align-items-center"
              visible={true}
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={spinnerContainerStyle}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
          />
      );
    }

    if (error) {
      return (
          <div>
            Error: {error}
            <br/>
            <a href="https://www.flexforce.ai/">Please login again</a>
          </div>
      );
    }

    return (
        <>
          <SimpleHeader title="Workers"
                        fetchWorkersData={this.fetchWorkersData}/>
          <Container className="mt--6" fluid>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader
                      className="border-0 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0 mr-2">Workers</h3>
                    <Input
                        type="text"
                        placeholder="Search by zipcode, interests, experience, or name..."
                        value={searchTerm}
                        onChange={this.handleSearch}
                    />
                  </CardHeader>
                  <div className="ag-theme-material"
                       style={{ height: "500px", width: "100%" }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={filteredWorkers}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                    />
                  </div>
                </Card>
              </div>
            </Row>
          </Container>
        </>
    );
  }
}

export default Sortable;
