import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.jsx";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    loading: false, // State for loading button
    errorModal: false, // State for error modal
    errorMessage: "" // Error message
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  toggleErrorModal = () => {
    this.setState(prevState => ({
      errorModal: !prevState.errorModal
    }));
  };

  handleSubmit = () => {
    // Set loading to true when the button is clicked
    this.setState({ loading: true });

    const { email, password } = this.state;
    fetch("https://flexforce-ai-50842c1c3cfd.herokuapp.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password })
    })
    .then(response => {
      // Handle response here
      if (response.ok) {
        return response.json(); // Parse response body as JSON
      } else {
        throw new Error('Login failed'); // Throw error if login failed
      }
    })
    .then(data => {
      localStorage.setItem('accessToken', data.access_token);
      this.props.history.push('/admin/dashboard');
    })
    .catch(error => {
      // Set error message
      this.setState({ errorMessage: error.message });
      // Toggle error modal
      this.toggleErrorModal();
    })
    .finally(() => {
      // Set loading to false when the request is completed
      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <>
        <AuthHeader
          title="Welcome!"
          lead="Use these awesome forms to login or create new account in your project for free."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>sign in with credentials</small>
                  </div>
                  <Form>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {/* Use a ternary operator to conditionally render the button text */}
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={this.handleSubmit}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? 'Signing in...' : 'Sign in'}
                      </Button>
                      {/* Show loader when loading state is true */}
                      {this.state.loading && <i className="fa fa-spinner fa-spin ml-2" />}
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a className="text-light" href="/auth/register">
                    <small>Create new account</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* Error modal */}
        <Modal isOpen={this.state.errorModal} toggle={this.toggleErrorModal}>
          <ModalHeader toggle={this.toggleErrorModal}>Error</ModalHeader>
          <ModalBody>
            {this.state.errorMessage}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleErrorModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Login;
