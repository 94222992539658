import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

class TimelineHeader extends React.Component {
  state = {
    modalOpen: false,
    selectedFile: null,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  handleFileChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loading: true,
    });
  };

  handleUpload = () => {
    const { selectedFile } = this.state;

    if (!selectedFile) {
      alert("Please choose a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    const accessToken = localStorage.getItem("accessToken");

    fetch("https://flexforce-ai-50842c1c3cfd.herokuapp.com/workers/import", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => response.json()).then((data) => {
      // Close the modal
      if (data.statusCode === 201) {
        this.setState({ modalOpen: false });
      }

      // Show success message
      alert(data.message);

      // Refresh the workers table (assuming you have a method to fetch and update the workers data)
      if (data.statusCode === 201) {
        this.props.fetchWorkersData();
      }
    }).catch((error) => {
      // You can handle error behavior here
    });
  };

  render() {
    const { modalOpen } = this.state;

    return (
        <>
          <div
              className="header header-dark bg-info pb-6 content__title content__title--calendar">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col className="mt-3 mt-md-0 text-md-right">
                    <Button
                        className="btn-neutral"
                        color="default"
                        size="sm"
                        onClick={this.toggleModal}
                    >
                      Import Workers
                    </Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Modal isOpen={modalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Import Workers</ModalHeader>
            <ModalBody>
              <input
                  type="file"
                  accept=".xlsx"
                  onChange={this.handleFileChange}
              />
            </ModalBody>
            <ModalFooter>

              <p>Download the <a
                  href="https://sample-sheet.s3.amazonaws.com/Workers.xlsx"
                  target="_blank" rel="noopener noreferrer">sample sheet</a></p>
              <Button color="primary" onClick={this.handleUpload}>
                Upload
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
