import Login from "views/pages/examples/Login.jsx";
import Pricing from "views/pages/examples/Pricing.jsx";
import Profile from "views/pages/examples/Profile.jsx";
import Register from "views/pages/examples/Register.jsx";
import Sortable from "views/pages/tables/Sortable.jsx";

const routes = [
  {
    path: "/",
    name: "Workers",
    icon: "ni ni-archive-2",
    component: Sortable,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Examples",
    className: "d-none",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        layout: "/auth"
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        layout: "/admin"
      }
    ]
  },
];

export default routes;
