import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { Redirect } from "react-router-dom";
import { baseUrl } from "variables/general";

class Register extends React.Component {
  state = {
    companyName: "",
    email: "",
    password: "",
    errorModal: false,
    errorMessage: "",
    successModal: false, // State for success modal
    redirectToLogin: false, // Updated state for redirection
    loading: false // State for loading button
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  toggleErrorModal = () => {
    this.setState(prevState => ({
      errorModal: !prevState.errorModal
    }));
  };

  toggleSuccessModal = () => {
    this.setState(prevState => ({
      successModal: !prevState.successModal
    }));
  };

  handleSubmit = () => {
    // Set loading to true when the button is clicked
    this.setState({ loading: true });

    const { companyName, email, password } = this.state;

    // Construct request body
    const requestBody = {
      password: password,
      email: email,
      company_name: companyName
    };

    // Make POST request
    fetch(`${baseUrl}/businesses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => {
      // Handle response
      if (response.ok) {
        console.log("Account created successfully!");
        // Toggle success modal on successful account creation
        this.toggleSuccessModal();
        // Set redirectToLogin to true after a delay
        setTimeout(() => {
          this.setState({ redirectToLogin: true });
        }, 3000); // Delay of 3 seconds (3000 milliseconds)
      } else {
        return response.json(); // Parse response body as JSON
      }
    })
    .then(data => {
      if (data) {
        // Extract error messages from response data
        const errorMessage = typeof data.message === 'string' ? data.message : data.message.join(", ");
        this.setState({ errorMessage });
        this.toggleErrorModal();
      }
    })
    .catch(error => {
      console.error("Error:", error);
    })
    .finally(() => {
      // Set loading to false when the request is completed
      this.setState({ loading: false });
    });
  };

  render() {
    // Redirect to /login if redirectToLogin state is true
    if (this.state.redirectToLogin) {
      return <Redirect to="/auth/login" />;
    }

    return (
      <>
        <AuthHeader
          title="Create an account"
          lead="Use these awesome forms to login or create new account in your project for free."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>sign up with credentials</small>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-briefcase-24" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Company Name"
                          type="text"
                          name="companyName"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">
                              I agree with the{" "}
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      {/* Use a ternary operator to conditionally render the button text */}
                      <Button className="mt-4" color="info" type="button" onClick={this.handleSubmit} disabled={this.state.loading}>
                        {this.state.loading ? 'Creating account...' : 'Create account'}
                      </Button>
                      {/* Show loader when loading state is true */}
                      {this.state.loading && <i className="fa fa-spinner fa-spin ml-2" />}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Success modal */}
        <Modal isOpen={this.state.successModal} toggle={this.toggleSuccessModal}>
          <ModalHeader toggle={this.toggleSuccessModal}>Success</ModalHeader>
          <ModalBody>
            Account created successfully! Redirecting to login page...
          </ModalBody>
          {/* No need for a footer in the success modal */}
        </Modal>
        {/* Error modal */}
        <Modal isOpen={this.state.errorModal} toggle={this.toggleErrorModal}>
          <ModalHeader toggle={this.toggleErrorModal}>Error</ModalHeader>
          <ModalBody>
            {this.state.errorMessage}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleErrorModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Register;
